import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

import rainforaVideo from "assets/images/rainfora/home/rainfora.mp4"
import bgImage from "assets/images/sensoria/allbg.png"
import homeImage from "assets/images/sensoria/home.jpg"
import logoImage from "assets/images/sensoria/logo.png"

const Home = props => {
  const history = useHistory()

  useEffect(() => {
    if (props.leftSideBarType == "default") setLeftSideBarSize(200)
    else setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  const [isMute, setIsMute] = useState(true)

  const restartVideo = () => {
    var video = document.getElementById("rainforaVideo")
    video.load();
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensoria</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        {/* <div className="container-fluid"> */}
        {/* {pageData &&
          pageData.map((object, index) => componentHandler(object, index))} */}
        {/* <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"> */}
        <Row className="p-0">
          <Col className="p-0">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <div
                  className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
                  style={{
                    maxHeight: "100vh",
                    backgroundImage: `url(${homeImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    // filter: "brightness(50%)",
                  }}
                >
                  <CardImg
                    src={logoImage}
                    style={{
                      height: "195px",
                      objectFit: "contain",
                      borderRadius: 16,
                      marginTop: "20%",
                      // borderTopLeftRadius: "16px",
                      // borderTopRightRadius: "16px",
                      // borderBottomLeftRadius: "16px",
                      // borderBottomRightRadius: "16px",
                      // cursor: "pointer",
                    }}
                  />
                  {/* <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/videoseries?si=rw3idKW36LYmLtlT&list=PLSxdDtyZ1mtvcok0amV653k5dRXlpqYEQ&autoplay=1&loop=1&controls=1&disablekb=1&fs=0&mute=1&loop=1"
                    title="Trinity Rainfora | Trinity | Architecture Film Animation"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    // allowfullscreen
                  ></iframe> */}
                  {/* <video
                    src={rainforaVideo}
                    width="1440"
                    height="680"
                    // controls
                    id="rainforaVideo"
                    autoPlay
                    muted={isMute}
                    playsInline
                    loop
                  /> */}
                </div>
              </CardBody>
            </Card>
            {/* <Card
              style={{
                backgroundColor: "#2F3D57CC",
                width: "125px",
                backdropFilter: "blur(20px)",
                borderRadius: "34px",
                position: "fixed",
                bottom: 0,
                right: 32 + leftSideBarSize,
              }}
              className="px-4 py-0 mb-3"
            >
              <Row className="justify-content-around py-2">
                <Col className="col-auto px-0">
                  <button
                    className={
                      "btn btn-outline-light btn-rounded font-weight-normal font-size-18 border-none text-center text-color-919695" +
                      (isMute ? " navigation-option mm-active" : "")
                    }
                    style={{
                      // borderRadius: "20px",
                      height: 50,
                      width: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      setIsMute(true)
                    }}
                  >
                    <i className="ph ph-speaker-x"></i>
                  </button>
                </Col>
                <Col className="col-auto px-0">
                  <button
                    className={
                      "btn font-weight-normal font-size-18 text-center text-color-919695 border-none" +
                      (isMute ? "" : " navigation-option mm-active")
                    }
                    style={{
                      // borderRadius: "20px",
                      height: 50,
                      width: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      setIsMute(false)
                    }}
                  >
                    <i className="ph ph-speaker-high"></i>
                  </button>
                </Col>
              </Row>
            </Card>
            <button
              className={
                "btn text-color-919695 btn-primary font-weight-normal font-size-30 border-none"
              }
              style={{
                backgroundColor: "#2F3D57CC",
                backdropFilter: "blur(20px)",
                height: "60px",
                width: "60px",
                position: "fixed",
                bottom: 16,
                right: 32 + leftSideBarSize + 125 + 16,
                borderRadius: "30px",
              }}
              onClick={restartVideo}
            >
              <i className="ph ph-arrow-clockwise"></i>
            </button> */}
          </Col>
        </Row>
        {/* ===== OLD ======
        <Card>
          <CardImg
            className="img-fluid"
            src={asset.heroHome}
            srcSet={`${asset.heroHome} 300w, ${asset.heroHomex2} 768w, ${asset.heroHomex3} 1280w`}
            alt="hero"
          />
          <CardImgOverlay className="text-center d-flex align-items-center justify-content-center">
            <Row className="">
              <Col className="">
                <CardTitle className="text-primary mt-0 hero-font-title">
                  {"Fitness, redefined"}
                </CardTitle>
                <CardText className="text-secondary hero-font-subtitle font-weight-bold">
                  {"In Hapivity, our vision is to take "}{" "}
                  <strong>Virtual Workout</strong>{" "}
                  {
                    " to another level. We believed that with the right motivation, everyone can achieve their fitness's goal."
                  }
                </CardText>
                <button
                  className="btn btn-primary font-weight-extrabold font-size-16 px-4"
                  style={{ borderRadius: "16px", height: "54px" }}
                  onClick={() => {
                    props.showJoinNowAction(true)
                  }}
                >
                  {"Join now"}
                </button>
              </Col>
            </Row>
          </CardImgOverlay>
        </Card>*/}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftSideBarType } = state.Layout
  return { leftSideBarType }
}

export default withRouter(connect(mapStateToProps, { showJoinNowAction })(Home))

Home.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftSideBarType: PropTypes.any,
}
