import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useRef } from "react"

import SimpleBar from "simplebar-react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import imageRainforestVR from "assets/images/rainfora/facilities/vr/rainforest_vr.webp"
import imageSkyrunVR from "assets/images/rainfora/facilities/vr/skyrun_vr.webp"
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer"

import facilitiesPinBlue from "assets/images/rainfora/facilities/facilities-pin.png"
import facilitiesPinRed from "assets/images/rainfora/facilities/facilities-pin-important.png"

import FacilitiesPoint from "./FacilitiesPoint"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import imageInfinityPool from "assets/images/sensoria/gallery/03. 50m Infinity Pool.webp"
import imageBBQTerrace from "assets/images/sensoria/gallery/06. BBQ Terrace.webp"
import imageMultiCourt from "assets/images/sensoria/gallery/05. Mini Futsal Court & Pickleball Court.webp"
import imageContemplationCreek from "assets/images/sensoria/gallery/07. Contemplation Creek.webp"
import imageGardenTheater from "assets/images/sensoria/gallery/04. Garden Theater.webp"
import imageSpaVilla from "assets/images/sensoria/gallery/11. Spa Villa.webp"
// import imageHydrotherapyPods from "assets/images/sensoria/gallery/10. Hydrotherapy Pods.webp"

import compassNorth from "assets/images/rainfora/north.png"
import bgImage from "assets/images/sensoria/allbg.png"

const facilitiesImages = {
  infinity: imageInfinityPool,
  bbq: imageBBQTerrace,
  multicourt: imageMultiCourt,
  contemplation: imageContemplationCreek,
  theater: imageGardenTheater,
  spavilla: imageSpaVilla,
  // hydrotherapy: imageHydrotherapyPods
}

import zoomGym from "assets/images/sensoria/facilities/zoom/A.Gym.webp"
import zoomMultiPurposeHall from "assets/images/sensoria/facilities/zoom/B.Multi_Purpose_Hall.webp"
import zoomLaundry from "assets/images/sensoria/facilities/zoom/C.Laundrypsd.webp"
import zoomMiniMart from "assets/images/sensoria/facilities/zoom/D.Mini_Mart.webp"
import zoomChangingRoomF from "assets/images/sensoria/facilities/zoom/E.Changing_Room_(F).webp"
import zoomChangingRoomM from "assets/images/sensoria/facilities/zoom/F.Changing_Room_(M).webp"
import zoomSpaVilla from "assets/images/sensoria/facilities/zoom/G.Spa_Villa.webp"
import zoomStudy from "assets/images/sensoria/facilities/zoom/H.Study.webp"
import zoomEntertainment from "assets/images/sensoria/facilities/zoom/I.Entertainment.webp"
import zoomSurau from "assets/images/sensoria/facilities/zoom/J.Surau.webp"

import zoom50mInfinityPool from "assets/images/sensoria/facilities/zoom/1.50m_InfInity_Pool.webp"
import zoomWading from "assets/images/sensoria/facilities/zoom/2.Wading.webp"
import zoomWetDeck from "assets/images/sensoria/facilities/zoom/3.Wet_Deck.webp"
import zoomTrailOfTranquility from "assets/images/sensoria/facilities/zoom/4.Trail_of_Tranquility.webp"
import zoomPicnicCourtyard from "assets/images/sensoria/facilities/zoom/5.Picnic_Coutyard.webp"
import zoomOpenTerrace from "assets/images/sensoria/facilities/zoom/6.Open_Terrace.webp"
import zoomOpenTerrace2 from "assets/images/sensoria/facilities/zoom/15.Open_Terrace.webp"
import zoomPoolTerrace from "assets/images/sensoria/facilities/zoom/7.Pool_Terrace.webp"
import zoomPoolShower from "assets/images/sensoria/facilities/zoom/8.Pool_Shower.webp"
import zoomMiniCourt from "assets/images/sensoria/facilities/zoom/9.Mini_Futsal_Tennis_And_Pickle_Ball_Court.webp"
import zoomYogaTabataPad from "assets/images/sensoria/facilities/zoom/10.Yoga_Tabata_Pad.webp"
import zoomCommunityLoop from "assets/images/sensoria/facilities/zoom/11.Community_Loop.webp"
import zoomFitnessNode from "assets/images/sensoria/facilities/zoom/12.Fitness_Node.webp"
import zoomFamilyPlayscape from "assets/images/sensoria/facilities/zoom/13.Family_Playscape.webp"
import zoomLoungeOfReflection from "assets/images/sensoria/facilities/zoom/14.Lounge_Of_Reflection.webp"
import zoomBreezyLanai from "assets/images/sensoria/facilities/zoom/16.Breezy_Lanai.webp"
import zoomOutdoorCinema from "assets/images/sensoria/facilities/zoom/17.Outdoor_Cinema.webp"
import zoomBBQTerrace from "assets/images/sensoria/facilities/zoom/18.BBQ_Terrace.webp"
import zoomPatio from "assets/images/sensoria/facilities/zoom/19.Patio.webp"
import zoomEdibleOrganicGarden from "assets/images/sensoria/facilities/zoom/20.Edible_Organic_Garden.webp"
import zoomContemplationNook from "assets/images/sensoria/facilities/zoom/21.Contemplation_Nook.webp"
import zoomTrailOfTranquility2 from "assets/images/sensoria/facilities/zoom/22.Trail_of_Tranquillity.webp"
import zoomSpaGarden from "assets/images/sensoria/facilities/zoom/23.Spa_Garden.webp"
import zoomJacuzziPavilion from "assets/images/sensoria/facilities/zoom/24.Jacuzzi_Pavilion.webp"
import zoomReflexologyCreek from "assets/images/sensoria/facilities/zoom/25.Reflexology_Creek.webp"
import zoomContemplationCreek from "assets/images/sensoria/facilities/zoom/26.Contemplation_Creek.webp"
import zoomSpaShowerPod from "assets/images/sensoria/facilities/zoom/27.Spa_Shower_Pod.webp"
import zoomRainBathPod from "assets/images/sensoria/facilities/zoom/28.Rain_Bath_Pod.webp"
import zoomWellnessLawn from "assets/images/sensoria/facilities/zoom/29.Wellness_Lawn.webp"
import zoomMeditationDeck from "assets/images/sensoria/facilities/zoom/30.Meditation_Deck.webp"
import zoomHydrotherapyPods from "assets/images/sensoria/facilities/zoom/26.Hydrotherapy_Pods.webp"
import zoomCafeteriaKitchen from "assets/images/sensoria/facilities/zoom/C.Cafetaria_And_Kitchen.webp"
import zoomUtilityRoom from "assets/images/sensoria/facilities/zoom/I.Utility_Room.webp"
import zoomChangingRoomFSouth from "assets/images/sensoria/facilities/zoom/F.South_Changing_Room_(F).webp"
import zoomChangingRoomFNorth from "assets/images/sensoria/facilities/zoom/F.North_Changing_Room_(F).webp"
import zoomChangingRoomMSouth from "assets/images/sensoria/facilities/zoom/G.South_Changing_Room_(M).webp"
import zoomChangingRoomMNorth from "assets/images/sensoria/facilities/zoom/G.North_Changing_Room_(G).webp"
import zoomMultipurposePatio from "assets/images/sensoria/facilities/zoom/19.Multipurpose_Patio.webp"

const zoomImage = {
  Gym: zoomGym,
  "Multi Purpose Hall": zoomMultiPurposeHall,
  Laundry: zoomLaundry,
  "Mini Mart": zoomMiniMart,
  "Changing Room (F)": zoomChangingRoomF,
  "Changing Room (M)": zoomChangingRoomM,
  "Spa Villa": zoomSpaVilla,
  "Study Room": zoomStudy,
  Entertainment: zoomEntertainment,
  Surau: zoomSurau,
  "50m Infinity Pool": zoom50mInfinityPool,
  "Wading Pool": zoomWading,
  "Wet Deck": zoomWetDeck,
  "Trail of Tranquility": zoomTrailOfTranquility,
  "Picnic Courtyard": zoomPicnicCourtyard,
  "Pool Terrace": zoomPoolTerrace,
  "Pool Shower": zoomPoolShower,
  "Pickleball Court / Mini Futsal": zoomMiniCourt,
  "Yoga Tabata Pad": zoomYogaTabataPad,
  "Community Loop": zoomCommunityLoop,
  "Fitness Node": zoomFitnessNode,
  "Family Playscape": zoomFamilyPlayscape,
  "Floating Garden": zoomLoungeOfReflection,
  "Breezy Lanai": zoomBreezyLanai,
  "Garden Theater": zoomOutdoorCinema,
  "BBQ Terrace": zoomBBQTerrace,
  Patio: zoomPatio,
  "Multipurpose Patio": zoomMultipurposePatio,
  "Edible Organic Garden": zoomEdibleOrganicGarden,
  "Contemplation Nook": zoomContemplationNook,
  "Trail of Tranquility 2": zoomTrailOfTranquility2,
  "Spa Garden": zoomSpaGarden,
  "Jacuzzi Pavilion": zoomJacuzziPavilion,
  "Reflexology Creek": zoomReflexologyCreek,
  "Contemplation Creek": zoomContemplationCreek,
  "Spa Shower Pod": zoomSpaShowerPod,
  "Rain Bath Pod": zoomRainBathPod,
  "Wellness Lawn": zoomWellnessLawn,
  "Meditation Deck": zoomMeditationDeck,
  "Open Terrace": zoomOpenTerrace,
  "Open Terrace 2": zoomOpenTerrace2,
  "Hydrotherapy Pods": zoomHydrotherapyPods,
  "Cafeteria/Kitchen": zoomCafeteriaKitchen,
  "Utility Room": zoomUtilityRoom,
  "Changing Room (F) 1": zoomChangingRoomFNorth,
  "Changing Room (F) 2": zoomChangingRoomFSouth,
  "Changing Room (M) 1": zoomChangingRoomMNorth,
  "Changing Room (M) 2": zoomChangingRoomMSouth,
}

import imageFullSpaGarden from "assets/images/sensoria/facilities/Facilities_ZoomIn_SpaGarden_FullHD.webp"
import imageFullWellnessRetreat from "assets/images/sensoria/facilities/Facilities_ZoomIn_WellnessRetreat_FullHD.webp"

const Facilities = props => {
  const history = useHistory()

  useEffect(() => {
    if(props.leftSideBarType == "default")
      setLeftSideBarSize(200)
    else
      setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const [def, setdef] = useState(50)
  const [dayNight, setDayNight] = useState(false)
  
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [facilitiesFloor, setFacilitiesFloor] = useState("WellnessRetreat")
  const [facilitiesPin, setFacilitiesPin] = useState(FacilitiesPoint.wellness)
  const [facilitiesClicked, setFacilitiesClicked] = useState(-1)

  const [facilitiesVR, setFacilitiesVR] = useState(false)
  const [facilitiesVRContent, setFacilitiesVRContent] = useState(
    imageRainforestVR
  )

  const [isFits, setisFits] = useState(false)
  const [lightBoxSrc, setLightboxSrc] = useState(null)
  const [lightBoxCaption, setLightboxCaption] = useState(null)

  const [isZoom, setIsZoom] = useState(false)

  const [optionShow, setOptionShow] = useState(false)

  const [floorWidth, setFloorWidth] = useState(0)
  const [floorHeight, setFloorHeight] = useState(0)
  const ref = useRef(null)

  const refSimpleBar = useRef()

  useEffect(() => {
    refSimpleBar.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        refSimpleBar.current.getScrollElement().scrollTop =
          currentPosition - 300
      }
    }
  }

  useEffect(() => {
    if (!ref.current) return
    setTimeout(() => {
      const { width, height } = ref.current.getBoundingClientRect()
      console.log("init", height)
      setFloorHeight(height / 2109)
      setFloorWidth(width / 4000)
    }, 1000)
  }, [])

  // useEffect(() => {
  //   if (!ref.current) return
  //   setTimeout(() => {
  //     const { width, height } = ref.current.getBoundingClientRect()
  //     console.log("init", height)
  //     setFloorHeight(height / 2160)
  //     setFloorWidth(width / 3840)
  //   }, 1000)
  // }, [leftSideBarSize])

  const simulateClick = elem => {
    let evt = new MouseEvent("click", {
      bubbles: true,
      view: window,
    })
    elem.dispatchEvent(evt)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sensoria</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          height: "100vh",
        }}
      >
        <img src={imageRainforestVR} style={{ display: "none" }} />
        <img src={imageSkyrunVR} style={{ display: "none" }} />
        {isFits ? (
          <Lightbox
            mainSrc={lightBoxSrc}
            enableZoom={false}
            imageTitle={lightBoxCaption}
            onCloseRequest={() => {
              setisFits(!isFits)
            }}
          />
        ) : null}
        {isZoom ? (
          <Lightbox
            mainSrc={
              facilitiesFloor == "SpaGarden"
                ? imageFullSpaGarden //imagePodiumList[def]
                : imageFullWellnessRetreat //imageRooftopList[def]
            }
            enableZoom={true}
            onCloseRequest={() => {
              setIsZoom(!isZoom)
            }}
          />
        ) : null}
        <Row className="">
          <Col className="">
            <Card
              className="p-0 mb-0"
              style={{ backgroundColor: "rgba(52, 52, 52, 0.0)" }}
            >
              {!facilitiesVR && (
                <CardBody
                  className="p-4"
                  style={{
                    height: "100vh",
                    marginLeft: 32 + leftSideBarSize + 218,
                    marginTop: 100,
                  }}
                >
                  <Row className="m-0 my-auto">
                    <Col style={{ paddingTop: "0px" }}>
                      <div
                        style={{ position: "relative", paddingBottom: "24px" }}
                        ref={ref}
                      >
                        {facilitiesPin.map(
                          (item, index) =>
                            item.top != 0 &&
                            item.left != 0 && (
                              <Row
                                key={"facilities-pin-" + index}
                                style={{
                                  position: "absolute",
                                  top: item.top * floorHeight - 30,
                                  left: item.left * floorWidth,
                                  zIndex: 1001,
                                }}
                              >
                                <Col className="align-self-center p-0">
                                  <img
                                    src={
                                      item.perspective
                                        ? facilitiesPinBlue
                                        : facilitiesPinRed
                                    }
                                    style={{
                                      border: "none",
                                      cursor: "pointer",
                                      // opacity: 0.7,
                                    }}
                                    id={"pin" + index}
                                    className={
                                      facilitiesClicked == index
                                        ? "zoom-in-out-box"
                                        : ""
                                    }
                                    onClick={() => {
                                      setFacilitiesClicked(index)
                                    }}
                                  />
                                </Col>
                                {!isFits && (
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    target={"pin" + index}
                                    placement="bottom"
                                  >
                                    <PopoverHeader>{item.title}</PopoverHeader>

                                    <PopoverBody>
                                      <button
                                        className={
                                          "btn text-white btn-outline-light btn-rounded font-weight-normal font-size-20 me-2"
                                        }
                                        onClick={() => {
                                          setisFits(true)
                                          setLightboxSrc(zoomImage[item.title])
                                          setLightboxCaption(item.title)
                                        }}
                                      >
                                        <i className="ph ph-magnifying-glass-plus"></i>
                                      </button>
                                      {item.perspective && (
                                        <button
                                          className={
                                            "btn text-white btn-outline-light btn-rounded font-weight-normal font-size-20 me-2"
                                          }
                                          onClick={() => {
                                            if (item.perspective) {
                                              setisFits(true)
                                              setLightboxSrc(
                                                facilitiesImages[
                                                  item.perspective
                                                ]
                                              )
                                              setLightboxCaption(item.title)
                                            }
                                          }}
                                        >
                                          <i className="ph ph-image-square"></i>
                                        </button>
                                      )}
                                      {item.full360 && (
                                        <button
                                          className={
                                            "btn text-white btn-outline-light btn-rounded font-weight-normal font-size-20 m-0"
                                          }
                                          onClick={() => {
                                            if (item.full360 == "skyrun") {
                                              setFacilitiesVR(true)
                                              setFacilitiesVRContent(
                                                imageSkyrunVR
                                              )
                                            }
                                            if (item.full360 == "rainforest") {
                                              setFacilitiesVR(true)
                                              setFacilitiesVRContent(
                                                imageRainforestVR
                                              )
                                            }
                                          }}
                                        >
                                          <i className="ph ph-cube-focus"></i>
                                        </button>
                                      )}
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                )}
                              </Row>
                            )
                        )}

                        <CardImg
                          src={
                            facilitiesFloor == "SpaGarden"
                              ? imageFullSpaGarden //imagePodiumList[def]
                              : imageFullWellnessRetreat //imageRooftopList[def]
                          }
                          style={{
                            // height: "295px",
                            // height: "100vh",
                            objectFit: "contain",
                          }}
                        />
                        <Row
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            // right: 32,
                            // backgroundImage:
                            //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                          }}
                          className="justify-content-end"
                        >
                          <Col className="col-auto">
                            <img
                              src={compassNorth}
                              style={{
                                width: 80,
                                height: 80,
                                position: "fixed",
                                top: 16,
                                right: 32,
                                rotate:
                                  facilitiesFloor == "SpaGarden"
                                    ? "45deg"
                                    : "-135deg",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      paddingTop: 16,
                      paddingLeft: 32,
                      paddingRight: 32,
                      // right: 32,
                      // backgroundImage:
                      //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                    }}
                  >
                    <Col>
                      <span className="text-primary font-size-56 font-weight-bold ">
                        {"FACILITIES"}
                      </span>
                    </Col>
                  </Row>
                  {/* <Row
                    style={{
                      position: "fixed",
                      bottom: 56,
                      left: leftSideBarSize + 300,
                      right: 300,
                    }}
                  >
                    <Col>
                      <Slider
                        value={def}
                        orientation="horizontal"
                        min={0}
                        max={100}
                        onChange={value => {
                          setdef(value)
                        }}
                        style={{
                          position: "fixed",
                          bottom: 30,
                          right: 10,
                        }}
                      />
                    </Col>
                  </Row> */}
                </CardBody>
              )}
              {facilitiesVR && (
                <ReactPhotoSphereViewer
                  src={facilitiesVRContent}
                  height={"100vh"}
                  width={"100%"}
                  maxFov={90}
                  minFov={50}
                  defaultZoomLvl={0}
                  navbar={false}
                ></ReactPhotoSphereViewer>
              )}
              {facilitiesVR && (
                <button
                  className={
                    "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none"
                  }
                  style={{
                    backgroundColor: "#2F3D57CC",
                    backdropFilter: "blur(20px)",
                    height: "50px",
                    // width: "60px",
                    position: "fixed",
                    bottom: 16,
                    left: 32 + leftSideBarSize,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setFacilitiesVR(false)
                  }}
                >
                  Close <i className="ph ph-x"></i>
                </button>
              )}
              {facilitiesVR && (
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                  }}
                >
                  <Col>
                    <span className="text-primary font-size-56 font-weight-bold text-uppercase">
                      {facilitiesPin[facilitiesClicked].title}
                    </span>
                  </Col>
                </Row>
              )}
              {/* {!isZoom && !optionShow && (
                <button
                  className={
                    "btn text-white btn-primary font-weight-normal font-size-22"
                  }
                  style={{
                    height: "60px",
                    width: "60px",
                    position: "fixed",
                    top: 16,
                    right: 108,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setIsZoom(!isZoom)
                  }}
                >
                  <i className="ph ph-magnifying-glass-plus"></i>
                </button>
              )} */}
            </Card>
          </Col>
        </Row>
        {!facilitiesVR && (
          <Card
            style={{
              backgroundColor: "#2F3D57CC",
              width: "218px",
              backdropFilter: "blur(20px)",
              borderRadius: "20px",
              position: "fixed",
              bottom: 135,
              left: 32 + leftSideBarSize,
            }}
            className="px-3 py-2 mb-3"
          >
            <SimpleBar style={{ maxHeight: "400px" }} ref={refSimpleBar}>
              <Row className="px-0">
                <Col className="px-3">
                  {facilitiesPin.map(
                    (item, index) =>
                      item.listed && (
                        <Row key={"option-facilities-" + index}>
                          <Col className="col-auto">
                            <button
                              className={
                                "btn btn-link font-size-16 p-0 text-decoration-none text-start"
                              }
                              onClick={() => {
                                let pinControl = document.getElementById(
                                  "pin" + index
                                )
                                simulateClick(pinControl)
                                setFacilitiesClicked(index)
                              }}
                            >
                              <span
                                className={
                                  "font-size-16" +
                                  (item.perspective
                                    ? " text-primary"
                                    : " text-white")
                                }
                              >
                                <i className="ph-fill ph-circle me-2"></i>
                              </span>{" "}
                              <span
                                className={
                                  "font-size-16 " +
                                  (facilitiesClicked == index
                                    ? "text-primary"
                                    : "text-white")
                                }
                              >
                                {item.title}
                              </span>
                            </button>
                          </Col>
                        </Row>
                      )
                  )}
                </Col>
              </Row>
            </SimpleBar>
          </Card>
        )}
        {!facilitiesVR && (
          <Card
            style={{
              backgroundColor: "#2F3D57CC",
              width: "218px",
              backdropFilter: "blur(20px)",
              borderRadius: "20px",
              position: "fixed",
              bottom: 0,
              left: 32 + leftSideBarSize,
            }}
            className="px-4 py-0 mb-3"
          >
            <Row>
              <Col>
                <Row className="my-2">
                  <button
                    className={
                      facilitiesFloor == "WellnessRetreat"
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (facilitiesFloor != "WellnessRetreat") {
                        setFacilitiesFloor("WellnessRetreat")
                        setFacilitiesPin(FacilitiesPoint.wellness)
                        setFacilitiesClicked(null)
                      }
                    }}
                  >
                    <span>{"Wellness Retreat"}</span>
                  </button>
                </Row>
                <Row className="my-2">
                  <button
                    className={
                      facilitiesFloor == "SpaGarden"
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (facilitiesFloor != "SpaGarden") {
                        setFacilitiesFloor("SpaGarden")
                        setFacilitiesPin(FacilitiesPoint.spa)
                        setFacilitiesClicked(null)
                      }
                    }}
                  >
                    <span>{"Spa Garden"}</span>
                  </button>
                </Row>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Facilities)
)

Facilities.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
